var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout-wrapper",
    [
      _c(
        "v-row",
        { staticClass: "ma-0" },
        [
          _c("v-col", { staticClass: "pa-0 left-col", attrs: { cols: "6" } }),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "pa-0 white d-flex flex-column",
              attrs: { cols: "6" },
            },
            [
              _c("div", { staticClass: "d-flex justify-end" }, [
                _c("div", { staticClass: "ma-5 text-right" }, [
                  _c("img", {
                    attrs: {
                      src: "/dist/images/logo.png",
                      width: "128px",
                      alt: "",
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "pa-14 d-flex justify-center flex-column flex-grow-1",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-h5 blue--text text--darken-4 font-weight-bold mb-6",
                    },
                    [_vm._v(_vm._s(_vm.$route.meta.title))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "router-container" },
                    [_c("router-view")],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }