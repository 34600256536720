var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-layout-wrapper", [
    _c(
      "div",
      { staticClass: "flex-grow-1 d-flex flex-column white" },
      [_c("router-view")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }