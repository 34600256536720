import {clearData, loadDataValue} from "../../helpers/common";

export default {
    state: {
        cities: [],
        housePrices: [],
        initialFees: [],
    },
    getters: {
        leadFilterCities(state) {
            return state.cities;
        },
        leadFilterHousePrices(state) {
            return state.housePrices;
        },
        leadFilterInitialFees(state) {
            return state.initialFees;
        },
    },
    mutations: {
        setLeadFilter(state, data) {
            if (data.cities !== undefined && Array.isArray(data.cities)) {
                loadDataValue(state.cities, data.cities);
            }

            if (data.housePrices && Array.isArray(data.housePrices)) {
                loadDataValue(state.housePrices, data.housePrices);
            }
            if (data.initialFees && Array.isArray(data.initialFees)) {
                loadDataValue(state.initialFees, data.initialFees);
            }
        },
    }
}